import {
  Typography,
  Box,
  Link,
  Card,
  CardContent,
  TextField,
  Button,
  Divider,
  InputAdornment,
  FormControl,
  Input,
  InputLabel,
} from "@mui/material";
import { ethers } from "ethers";
import { useCallback, useEffect, useState } from "react";
import { IERC20 } from "./lib/abi";
import { Config } from "./lib/config";
import { GridPosition } from "./lib/contract";
import { getTokenByAddress, Token } from "./lib/token";
import { addDecimals, removeDecimals } from "./lib/utils";
import { Token as V3Token } from "@uniswap/sdk-core";
import { nearestUsableTick, Pool as V3Pool, TickMath } from "@uniswap/v3-sdk";
import IUniswapV3Pool from "@uniswap/v3-core/artifacts/contracts/interfaces/IUniswapV3Pool.sol/IUniswapV3Pool.json";
import { createUseStyles } from "react-jss";
import { getOutputAmount } from "./lib/algorithm";
import useInterval from "@use-it/interval";

const useStyles = createUseStyles({
  gridPositionCards: {
    display: "flex",
    flexDirection: "row",
    marginTop: "24px",
    flexWrap: "wrap",
  },

  gridPositionCard: {
    minWidth: "300px",
    maxWidth: "100%",
    margin: "6px",
  },
  "@media (max-width: 600px)": {
    gridPositionCards: {
      flexDirection: "column",
    },
    gridPositionCard: {
      maxWidth: "100%",
    },
  },
});

interface GridPositionCardProps {
  gridTransactionContract: ethers.Contract;
  position: GridPosition;
  baseToken: Token;
  quoteToken: Token;
  totalValue: number;
  index: number;
  isContractOwner: boolean;
}

function GridPositionCard(props: GridPositionCardProps) {
  const gridTransactionContract = props.gridTransactionContract;
  const position = props.position;
  const baseToken = props.baseToken;
  const quoteToken = props.quoteToken;
  const totalValue = props.totalValue;
  const index = props.index;
  const classes = useStyles();
  const [showSellFields, setShowSellFields] = useState<boolean>(false);
  const [slippage, setSlippage] = useState<string>("0.5");

  const sellGridPosition = useCallback(async () => {
    const minimumAmountToReceive = addDecimals(
      totalValue * (1 - parseFloat(slippage || "0") / 100),
      baseToken.decimals
    );
    try {
      const tx = await gridTransactionContract.sellGridPosition(
        position.id,
        minimumAmountToReceive
      );
      await tx.wait();
      window.location.reload();
    } catch (error: any) {
      alert(error?.data?.message || error);
    }
  }, [
    gridTransactionContract,
    slippage,
    position,
    index,
    totalValue,
    baseToken,
  ]);

  return (
    <Card key={`position-${position.id}`} className={classes.gridPositionCard}>
      <CardContent>
        <Typography variant={"h6"}>ID: {position.id.toNumber()}</Typography>
        {baseToken && (
          <Typography>
            Invested{"  "}
            {removeDecimals(
              position.baseTokenAmountIn,
              baseToken.decimals
            )}{" "}
            <span style={{ color: "#f44336" }}>{baseToken.symbol}</span>
          </Typography>
        )}
        {quoteToken && (
          <Typography>
            Holding{" "}
            {removeDecimals(position.quoteTokenAmountOut, quoteToken.decimals)}{" "}
            <span style={{ color: "#2196f3" }}>{quoteToken.symbol}</span>
          </Typography>
        )}
        {!!totalValue && (
          <Typography>
            Current total value {totalValue.toFixed(2)}{" "}
            <span style={{ color: "#f44336" }}>{baseToken.symbol}</span>
          </Typography>
        )}
        {props.isContractOwner && (
          <>
            {!showSellFields ? (
              <Button
                variant={"contained"}
                color={"warning"}
                style={{ marginTop: "8px" }}
                onClick={() => setShowSellFields(true)}
              >
                Sell
              </Button>
            ) : (
              <Box style={{ marginTop: "8px" }}>
                <Divider style={{ marginBottom: "12px" }}></Divider>
                <FormControl fullWidth sx={{ m: 1 }} variant="standard">
                  <InputLabel htmlFor="standard-adornment-amount">
                    Swap slippage
                  </InputLabel>
                  <Input
                    id="standard-adornment-amount"
                    value={slippage}
                    onChange={(event) => setSlippage(event.target.value)}
                    startAdornment={
                      <InputAdornment position="start">%</InputAdornment>
                    }
                  />
                </FormControl>
                <Typography>
                  Minimum ${baseToken.symbol} to receive{" "}
                  {props.totalValue * (1 - parseFloat(slippage || "0") / 100)}
                </Typography>
                <Box style={{ marginTop: "8px" }}>
                  <Button
                    variant={"contained"}
                    color={"error"}
                    onClick={sellGridPosition}
                  >
                    Confirm Sell
                  </Button>
                  <Button
                    variant={"contained"}
                    color={"warning"}
                    style={{ marginLeft: "8px" }}
                    onClick={() => setShowSellFields(false)}
                  >
                    Cancel
                  </Button>
                </Box>
              </Box>
            )}
          </>
        )}
      </CardContent>
    </Card>
  );
}

interface WithdrawCardProps {
  gridTransactionContract: ethers.Contract;
  token: Token;
  balance: number;
  contractOwnerAddress: string;
  tokenColor?: string;
}
function WithdrawCard(props: WithdrawCardProps) {
  const classes = useStyles();
  const [withdrawTokenAmount, setWithdrawTokenAmount] = useState<string>("");
  const [withdrawRecipientAddress, setWithdrawRecipientAddress] =
    useState<string>("");
  const [isRunningTransaction, setIsRunningTransaction] =
    useState<boolean>(false);

  const withdraw = useCallback(async () => {
    const recipientAddress = (
      withdrawRecipientAddress || props.contractOwnerAddress
    ).trim();
    if (
      ethers.utils.isAddress(recipientAddress) &&
      props.token &&
      !isNaN(parseFloat(withdrawTokenAmount))
    ) {
      const amount = addDecimals(withdrawTokenAmount, props.token.decimals);
      try {
        setIsRunningTransaction(true);
        const tx = await props.gridTransactionContract.withdraw(
          props.token.address,
          amount,
          recipientAddress
        );
        await tx.wait();
        setIsRunningTransaction(false);
        alert("Successfully withdrawed");
      } catch (error) {
        setIsRunningTransaction(false);
      }
    }
  }, [
    props.token,
    withdrawTokenAmount,
    withdrawRecipientAddress,
    props.contractOwnerAddress,
    props.gridTransactionContract,
  ]);

  return (
    <Card className={classes.gridPositionCard}>
      <CardContent>
        <TextField
          margin={"dense"}
          fullWidth={true}
          label={`${props.token.symbol} to withdraw, max withdrawable ${props.balance}`}
          value={withdrawTokenAmount}
          onChange={(event: any) => setWithdrawTokenAmount(event.target.value)}
        ></TextField>
        <TextField
          margin="dense"
          fullWidth={true}
          label={`Recipient adress. If empty then will send to ${props.contractOwnerAddress}`}
          value={withdrawRecipientAddress}
          onChange={(event) => setWithdrawRecipientAddress(event.target.value)}
        ></TextField>
        {ethers.utils.isAddress(
          withdrawRecipientAddress.trim() || props.contractOwnerAddress
        ) &&
          !isNaN(parseFloat(withdrawTokenAmount)) && (
            <>
              <Typography>
                Will withdraw to{" "}
                {withdrawRecipientAddress.trim() || props.contractOwnerAddress}
                {" with "}
                <strong>{withdrawTokenAmount}</strong>{" "}
                <span style={{ color: props.tokenColor || "#f44336" }}>
                  {props.token.symbol}
                </span>
              </Typography>
              <Button
                fullWidth={true}
                variant={"contained"}
                onClick={withdraw}
                style={{ marginTop: "6px" }}
                disabled={isRunningTransaction}
              >
                Withdraw
              </Button>
            </>
          )}
      </CardContent>
    </Card>
  );
}

interface Props {
  signer:
    | ethers.providers.JsonRpcSigner
    | ethers.providers.JsonRpcProvider
    | undefined;
  gridTransactionContract: ethers.Contract;
  config: Config;
}

export default function Dashboard(props: Props) {
  const [gridPositions, setGridPositions] = useState<GridPosition[]>([]);
  const [baseToken, setBaseToken] = useState<Token | null>(null);
  const [quoteToken, setQuoteToken] = useState<Token | null>(null);
  const [baseTokenBalance, setBaseTokenBalance] = useState<number>(0);
  const [quoteTokenBalance, setQuoteTokenBalance] = useState<number>(0);
  const [gridPositionsQuoteTokenValues, setGridPositionsQuoteTokenValues] =
    useState<number[]>([]);
  const [baseTokenProfit, setBaseTokenProfit] = useState<number>(0);
  const [baseTokenWithdrawAmount, setBaseTokenWithdrawAmount] =
    useState<number>(0);
  const [contractOwnerAddress, setContractOwnerAddress] = useState<string>("");
  const [signerAddress, setSignerAddress] = useState<string>("");
  const [updateInterval, setUpdateInterval] = useState<number>(0);
  const classes = useStyles();

  useEffect(() => {
    (async () => {
      setBaseToken(
        await getTokenByAddress(props.config.baseTokenAddress, props.signer)
      );
      setQuoteToken(
        await getTokenByAddress(props.config.quoteTokenAddress, props.signer)
      );
    })();
  }, [props.config, props.signer]);

  useEffect(() => {
    (async () => {
      const owner = await props.gridTransactionContract.owner();
      setContractOwnerAddress(owner);
    })();
  }, [props.gridTransactionContract]);

  useEffect(() => {
    if (props.signer && "getAddress" in props.signer) {
      props.signer
        .getAddress()
        .then((address) => setSignerAddress(address))
        .catch(() => setSignerAddress(""));
    } else {
      setSignerAddress("");
    }
  }, [props.signer]);

  useEffect(() => {
    (async () => {
      if (baseToken) {
        const tokenContract = new ethers.Contract(
          baseToken.address,
          IERC20,
          props.signer
        );
        const balance = await tokenContract.balanceOf(
          props.gridTransactionContract.address
        );
        setBaseTokenBalance(removeDecimals(balance, baseToken.decimals));
      }
    })();
  }, [baseToken, props.signer, props.gridTransactionContract, updateInterval]);

  useEffect(() => {
    (async () => {
      if (quoteToken) {
        const tokenContract = new ethers.Contract(
          quoteToken.address,
          IERC20,
          props.signer
        );
        const balance = await tokenContract.balanceOf(
          props.gridTransactionContract.address
        );
        setQuoteTokenBalance(removeDecimals(balance, quoteToken.decimals));
      }
    })();
  }, [quoteToken, props.signer, props.gridTransactionContract, updateInterval]);

  useEffect(() => {
    (async () => {
      const positions: GridPosition[] =
        await props.gridTransactionContract.getPositions(
          props.config.baseTokenAddress,
          props.config.quoteTokenAddress,
          props.config.poolFee
        );
      setGridPositions(positions);
    })();
  }, [props.gridTransactionContract, props.config, updateInterval]);

  useEffect(() => {
    (async () => {
      if (!baseToken || !quoteToken) {
        return;
      }
      const v3PoolBaseToken: V3Token = new V3Token(
        props.config.chainId,
        baseToken.address,
        baseToken.decimals,
        baseToken.symbol
      );
      const v3PoolQuoteToken: V3Token = new V3Token(
        props.config.chainId,
        quoteToken.address,
        quoteToken.decimals,
        quoteToken.symbol
      );
      const poolContract = new ethers.Contract(
        props.config.poolAddress,
        IUniswapV3Pool.abi,
        props.signer
      );
      const [liquidity_, slot, tickSpacing, poolFee] = await Promise.all([
        poolContract.liquidity(),
        poolContract.slot0(),
        poolContract.tickSpacing(),
        poolContract.fee(),
      ]);
      const liquidity: ethers.BigNumber = liquidity_;
      const sqrtPriceX96: ethers.BigNumber = slot[0];
      const tick: number = slot[1];

      const v3Pool: V3Pool = new V3Pool(
        v3PoolBaseToken,
        v3PoolQuoteToken,
        poolFee,
        sqrtPriceX96.toString(),
        liquidity.toString(),
        tick,
        [
          {
            index: nearestUsableTick(TickMath.MIN_TICK, tickSpacing),
            liquidityNet: parseInt(liquidity.toString()),
            liquidityGross: parseInt(liquidity.toString()),
          },
          {
            index: nearestUsableTick(TickMath.MAX_TICK, tickSpacing),
            liquidityNet: parseInt(liquidity.mul(-1).toString()),
            liquidityGross: parseInt(liquidity.toString()),
          },
        ]
      );

      const values = [];
      for (let i = 0; i < gridPositions.length; i++) {
        const position = gridPositions[i];
        const value = await getOutputAmount(
          v3Pool,
          v3PoolQuoteToken,
          v3PoolBaseToken,
          removeDecimals(position.quoteTokenAmountOut, quoteToken.decimals)
        );
        values.push(value);
      }
      setGridPositionsQuoteTokenValues(values);
    })();
  }, [gridPositions, baseToken, quoteToken, props.config, props.signer]);

  useEffect(() => {
    (async () => {
      if (props.gridTransactionContract && baseToken) {
        const profit = await props.gridTransactionContract.profits(
          baseToken.address
        );
        setBaseTokenProfit(removeDecimals(profit, baseToken.decimals));

        const withdrawAmount = await props.gridTransactionContract.withdrawals(
          baseToken.address
        );
        setBaseTokenWithdrawAmount(
          removeDecimals(withdrawAmount, baseToken.decimals)
        );
      }
    })();
  }, [props.gridTransactionContract, baseToken, updateInterval]);

  useInterval(() => {
    setUpdateInterval(Date.now());
  }, 10000);

  return (
    <Box>
      <Typography variant={"body1"}>
        Grid Transaction Contract:{" "}
        <Link
          href={`https://polygonscan.com/address/${props.gridTransactionContract.address}`}
          target={"_blank"}
          rel={"noreferrer"}
          title={props.gridTransactionContract.address}
        >
          {props.gridTransactionContract.address.slice(0, 10) + "..."}
        </Link>
      </Typography>
      <Typography>
        <strong>{gridPositions.length}</strong> working grid positions
      </Typography>
      {!!baseToken && (
        <Typography>
          The contract is holding {baseTokenBalance}{" "}
          <span style={{ color: "#f44336" }}>{baseToken.symbol}</span>
        </Typography>
      )}
      {!!quoteToken && (
        <Typography>
          The contract is holding {quoteTokenBalance}{" "}
          <span style={{ color: "#2196f3" }}>{quoteToken.symbol}</span>
        </Typography>
      )}
      {!!baseToken &&
        !!quoteToken &&
        gridPositions.length === gridPositionsQuoteTokenValues.length && (
          <>
            <Typography>
              All grid positions are holding in total{" "}
              {gridPositionsQuoteTokenValues
                .reduce((x, y) => x + y, 0)
                .toFixed(2)}{" "}
              <span style={{ color: "#f44336" }}>{baseToken.symbol}</span>
            </Typography>
            <Typography>
              Total:{" "}
              {gridPositionsQuoteTokenValues
                .reduce((x, y) => x + y, 0)
                .toFixed(2)}
              {" + "}
              {baseTokenBalance}
              {" = "}
              {(
                gridPositionsQuoteTokenValues.reduce((x, y) => x + y, 0) +
                baseTokenBalance
              ).toFixed(2)}{" "}
              <span style={{ color: "#f44336" }}>{baseToken.symbol}</span>{" "}
            </Typography>
            <Typography>
              Accumulated profit {baseTokenProfit}{" "}
              <span style={{ color: "#f44336" }}>{baseToken.symbol}</span> -
              Withdrawed {baseTokenWithdrawAmount}{" "}
              <span style={{ color: "#f44336" }}>{baseToken.symbol}</span> ={" "}
              {baseTokenProfit - baseTokenWithdrawAmount}{" "}
              <span style={{ color: "#f44336" }}>{baseToken.symbol}</span>
            </Typography>

            <Box className={classes.gridPositionCards}>
              {gridPositions.map((position, index) => {
                return (
                  <GridPositionCard
                    gridTransactionContract={props.gridTransactionContract}
                    position={position}
                    baseToken={baseToken}
                    quoteToken={quoteToken}
                    totalValue={gridPositionsQuoteTokenValues[index] || 0}
                    key={position.id.toNumber()}
                    index={index}
                    isContractOwner={
                      signerAddress.toLowerCase() ===
                      contractOwnerAddress.toLowerCase()
                    }
                  ></GridPositionCard>
                );
              })}
            </Box>
          </>
        )}
      <Box className={classes.gridPositionCards}>
        {!!baseToken &&
          contractOwnerAddress.toLowerCase() ===
            signerAddress.toLowerCase() && (
            <WithdrawCard
              token={baseToken}
              balance={baseTokenBalance}
              gridTransactionContract={props.gridTransactionContract}
              contractOwnerAddress={contractOwnerAddress}
            ></WithdrawCard>
          )}
        {!!quoteToken &&
          contractOwnerAddress.toLowerCase() ===
            signerAddress.toLowerCase() && (
            <WithdrawCard
              token={quoteToken}
              balance={quoteTokenBalance}
              gridTransactionContract={props.gridTransactionContract}
              contractOwnerAddress={contractOwnerAddress}
              tokenColor={"#2196f3"}
            ></WithdrawCard>
          )}
      </Box>
    </Box>
  );
}
