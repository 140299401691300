import { ethers } from "ethers";
import { IERC20 } from "./abi";

export interface Token {
  chainId: number;
  address: string;
  decimals: number;
  symbol: string;
  name: string;
}

const _addressTokenMapCache: { [key: string]: Token } = {};
export async function getTokenByAddress(
  tokenAddress: string,
  signer:
    | ethers.providers.JsonRpcSigner
    | ethers.providers.JsonRpcProvider
    | undefined
): Promise<Token> {
  if (tokenAddress in _addressTokenMapCache) {
    return _addressTokenMapCache[tokenAddress];
  } else {
    const tokenContract = new ethers.Contract(tokenAddress, IERC20, signer);
    const [decimals, symbol, name] = await Promise.all([
      tokenContract.decimals(),
      tokenContract.symbol(),
      tokenContract.name(),
    ]);
    const token: Token = {
      chainId: 136,
      address: tokenAddress,
      decimals,
      symbol,
      name,
    };
    _addressTokenMapCache[tokenAddress] = token;
    return token;
  }
}
