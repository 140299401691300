import React, { useEffect, useState } from "react";
import "./App.css";
import { ethers } from "ethers";
import { myConfig } from "./lib/config";
import { IUniswapV3GridTransaction } from "./lib/abi";
import { Container, Box, Typography } from "@mui/material";
import Dashboard from "./Dashboard";

// Polygon network chainId: 137

/*
// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyDzFokq-qvIfKJJKFlWPqY-K69zyjup7Zg",
  authDomain: "rich-yiyi.firebaseapp.com",
  projectId: "rich-yiyi",
  storageBucket: "rich-yiyi.appspot.com",
  messagingSenderId: "184032101558",
  appId: "1:184032101558:web:405fa28de4e179b8a79217"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
*/

function App() {
  const [provider, setProvider] = useState<
    ethers.providers.Web3Provider | ethers.providers.JsonRpcProvider | undefined
  >(undefined);
  const [signer, setSigner] = useState<
    ethers.providers.JsonRpcSigner | undefined
  >(undefined);
  const [network, setNetwork] = useState<ethers.providers.Network | undefined>(
    undefined
  );
  const [gridTransactionContract, setGridTransactionContract] =
    useState<ethers.Contract | null>(null);

  useEffect(() => {
    (async () => {
      const ethereum = (window as any)["ethereum"];
      if (ethereum) {
        await ethereum.request({ method: "eth_requestAccounts" });
        const provider = new ethers.providers.Web3Provider(ethereum);
        const network = await provider.getNetwork();
        setProvider(provider);
        setNetwork(network);
        // await provider.send("eth_requestAccounts", []);
        const signer = provider.getSigner();
        setSigner(signer);
        ethereum.on("accountsChanged", function () {
          const signer = provider.getSigner();
          setSigner(signer);
        });
        ethereum.on("chainChanged", async function () {
          window.location.reload();
        });

        // Connect to UniswapV3LiquidityBalancer contract
      } else {
        try {
          const provider = new ethers.providers.JsonRpcProvider(
            "https://polygon-rpc.com"
          );
          const network = await provider.getNetwork();
          setProvider(provider);
          setNetwork(network);
          setSigner(undefined);
        } catch (error) {
          setProvider(undefined);
        }
      }
    })();
  }, []);

  useEffect(() => {
    (async () => {
      if (network) {
        if (network.chainId === 137) {
          // Ropsten
          const gridTransactionContract = new ethers.Contract(
            myConfig.gridTransactionContractAddress,
            IUniswapV3GridTransaction,
            signer || provider
          );
          setGridTransactionContract(gridTransactionContract);
        } else {
          setGridTransactionContract(null);
        }
      } else {
        setGridTransactionContract(null);
      }
    })();
  }, [network, signer, provider]);

  return (
    <Container className={"App"}>
      {!!provider && !!gridTransactionContract ? (
        <Dashboard
          signer={signer || provider}
          gridTransactionContract={gridTransactionContract}
          config={myConfig}
        ></Dashboard>
      ) : (
        <Box>
          <Typography>
            {"Please connect to MetaMask and switch to Polygon Network"}
          </Typography>
        </Box>
      )}
    </Container>
  );
}

export default App;
