import regression, { DataPoint } from "regression";
import { addDecimals } from "./utils";
import { nearestUsableTick, Pool as V3Pool, TickMath } from "@uniswap/v3-sdk";
import { CurrencyAmount, Token as V3Token } from "@uniswap/sdk-core";

export function calculateBaseUSDPrice(prices: number[], buyInStepRate = 0.01) {
  const data: DataPoint[] = [];
  let sumPrice = 0;
  let minPrice = Infinity;
  let maxPrice = 0;
  for (let i = 0; i < prices.length; i++) {
    const usdPrice = prices[i];
    if (usdPrice < minPrice) {
      minPrice = usdPrice;
    }
    if (usdPrice > maxPrice) {
      maxPrice = usdPrice;
    }

    sumPrice += usdPrice;

    data.push([i, usdPrice]);
  }
  const averagePrice = sumPrice / prices.length;
  const result = regression.linear(data, { precision: 6 });
  const gradient = result.equation[0];
  const yIntercept = result.equation[1];
  console.log("\ncalculateBaseUSDPrice");
  console.log("* prices.length: ", prices.length);
  console.log("* averagePrice: ", averagePrice);
  console.log("* minPrice: ", minPrice);
  console.log("* maxPrice: ", maxPrice);
  // console.log("* regression result: ", result);
  console.log("* gradient: ", gradient);
  console.log("* yIntercept: ", yIntercept);
  const finalRegressionPrice = gradient * (prices.length - 1) + yIntercept;
  // console.log("* finalRegressionPrice: ", finalRegressionPrice);
  const finalPrice = prices[prices.length - 1];
  let basePrice = 0;
  // console.log("* finalPrice: ", finalPrice);
  if (gradient > 0) {
    if (finalPrice > finalRegressionPrice) {
      basePrice = (finalPrice + finalRegressionPrice) / 2;
    } else {
      basePrice = finalPrice;
    }
  } else {
    if (finalPrice < finalRegressionPrice) {
      basePrice = 0;
    } else {
      basePrice = (finalPrice + finalRegressionPrice) / 2;
    }

    // TODO: I hard code the adjusted minPrice here
    // minPrice = minPrice * 0.9;
  }

  let gridsCount = 0;
  let price = minPrice;
  while (price < maxPrice) {
    gridsCount += 1;
    price = price * (1 + buyInStepRate);
  }

  return { basePrice, gridsCount };
}

export async function getOutputAmount(
  pool: V3Pool | undefined,
  tokenIn: V3Token | undefined,
  tokenOut: V3Token | undefined,
  amountIn: number
): Promise<number> {
  if (!pool || !tokenIn || !tokenOut || !amountIn) {
    return 0;
  }
  const result = await pool.getOutputAmount(
    CurrencyAmount.fromRawAmount(
      tokenIn,
      parseInt(addDecimals(amountIn, tokenIn.decimals).toString())
    )
  );
  return parseFloat(result[0].toFixed(tokenOut.decimals));
}
