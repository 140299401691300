import { ethers } from "ethers";

export function removeDecimals(
  amount: ethers.BigNumber,
  decimals: number
): number {
  return parseFloat(amount.toString()) / Math.pow(10, decimals);
}

export function addDecimals(
  amount: number | string,
  decimals: number
): ethers.BigNumber {
  return ethers.utils.parseUnits(
    (typeof amount === "string" ? parseFloat(amount) : amount).toFixed(
      decimals
    ),
    decimals
  );
}
