import { ethers } from "ethers";

export interface Config {
  buyInStepRate: number;
  sellOutProfitRate: number;
  initialAmountOfBaseTokenToInvestPerGrid: number;
  /**
   * For example, initialAmountOfBaseTokenToInvestPerGrid = 1000, baseTokenToInvestPerGridIncreaseRate = 0.1
   * Then 1st grid position will invest 1000 base token
   *      2nd grid position will invest 1000 * Math.pow(1 + 0.1, 1) = 1100 base token
   *      3rd grid position will invest 1000 * Math.pow(1 + 0.1, 2) = 1210 base token
   */
  baseTokenToInvestPerGridIncreaseRate: number;
  baseTokenToInvestPerGridIncreaseRateMaximumGridsCount: number;

  baseTokenAddress: string;
  quoteTokenAddress: string;
  poolFee: number;
  poolAddress: string;
  quoteTokenCoinGeckoId: string;

  chainId: number;
  gridTransactionContractAddress: string;
  walletAddress: string;

  basePriceUpdateInterval: number;
  gridTransactionCheckInterval: number;
  basePriceHistoryDuration: number;
  additionalGasLimit: ethers.BigNumber;
  swapSlippage: number;
  minimumBalanceForGasRequired: number;
  minimumGasPrice: ethers.BigNumber;
  maximumGasPrice: ethers.BigNumber;
}

const swapSlippage = 0.005;
export const myConfig: Config = {
  buyInStepRate: 0.01 + swapSlippage,
  sellOutProfitRate: 0.01,
  initialAmountOfBaseTokenToInvestPerGrid: 1000,
  baseTokenToInvestPerGridIncreaseRate: 0.1,
  baseTokenToInvestPerGridIncreaseRateMaximumGridsCount: 5,

  baseTokenAddress: ethers.utils.getAddress(
    "0x2791bca1f2de4661ed88a30c99a7a9449aa84174"
  ), // USDC
  quoteTokenAddress: ethers.utils.getAddress(
    "0x0d500b1d8e8ef31e21c99d1db9a6444d3adf1270"
  ), // WMATIC
  poolFee: 500,
  poolAddress: ethers.utils.getAddress(
    "0xa374094527e1673a86de625aa59517c5de346d32"
  ), // MATIC / USDC 0.05%
  quoteTokenCoinGeckoId: "matic-network",

  chainId: 136,
  gridTransactionContractAddress: ethers.utils.getAddress(
    "0x67937D08fEd6e832204C3CA56D6b0bD38Bf4a2A0"
  ),
  walletAddress: "0x58860b4a690e9f730e7f4c7d9eb6af2966a84613",

  basePriceUpdateInterval: 1000 * 60 * 30, // every 30 minutes
  gridTransactionCheckInterval: 1000 * 60 * 2, // every 2 minutes
  basePriceHistoryDuration: 1000 * 60 * 60 * 12, // calculate basePrice based on the past 12 hour prices data
  additionalGasLimit: ethers.BigNumber.from("20000"),
  swapSlippage: swapSlippage,
  minimumBalanceForGasRequired: 0.5, // 0.5 MATIC

  minimumGasPrice: ethers.utils.parseUnits("30", "gwei"),
  maximumGasPrice: ethers.utils.parseUnits("160", "gwei"),
};
